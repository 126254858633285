<template>
    <card body-class="flex flex__column" class="canned-response-template pb-10 border-radius">
        <div class="flex flex__align-center">
            <div class="icon-btn cursor-default">
                <svg-importer icon-name="icons/en" />
            </div>

            <strong class="text-subheader ml-6">{{ value.name }}</strong>
        </div>

        <div class="pt-6 pb-6 pl-6 pr-6 mt-4 canned-response-template__content">   
            <div class="text-body canned-response-template__text">
                {{ value.content }}
            </div>
        </div>
    </card>
</template>

<script>
export default {
    props: {
        value: { 
            type: Object,
            default: () => { return {} },
        },
    },
}
</script>

<style lang="scss">
.canned-response-template {
    &__content {
        background-color: rgba(242, 242, 242, 0.6);
        border-radius: 4px;
    }

    &__text {
        white-space: pre-line;
    }
}
</style>